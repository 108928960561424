/*-------------The beginning of the reset-------------------*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    /* font-size: 100%; */
    vertical-align: baseline;
    background: transparent;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    /* font-size: inherit; */
    font-weight: 400;
}
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
:focus,
:active {
    outline: none;
}
a:focus,
a:active {
    outline: none;
}

body, html {
    line-height: 1;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    min-width: 320px;
    width: 100%;
}
body {
    min-height: 100vh;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
ul li {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    outline: none;
    text-decoration: none;
}
a:hover,
a:visited {
    text-decoration: none;
}
button::-moz-focus-inner {
    padding: 0;
    border: 0;
}
button {
    cursor: pointer;
}
/* change colours to suit your needs */
ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
}
/* change colours to suit your needs */
mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: bold;
}
del {
    text-decoration: line-through;
}
abbr[title],
dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
/* change border colour to suit your needs */
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #cccccc;
    margin: 1em 0;
    padding: 0;
}
input,
select {
    vertical-align: middle;
}
input,
button,
textarea {
    font-family: inherit;
}
input::-ms-clear {
    display: none;
}
img {
    vertical-align: top;
}
/*-------------The end of the reset-------------*/


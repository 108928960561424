$firstColor: #f2b724;
$secondColor: #008783;
$thirdColor: #001f25;
$forthColor: #fbfbfb;
$fivethColor: #969696;

@mixin mobile {
    @media (max-width: 480px) {
        @content;
    }
}
@mixin tablet {
    @media (max-width: 900px) {
        @content;
    }
}
@mixin decktop {
    @media (max-width: 1120px) {
        @content;
    }
}

.app {
    display: flex;
    justify-content: center;
    overflow: hidden;

    .container {
        width: 100%;
        max-width: 1440px;
        padding: 30px;
        border: 0.5px solid $firstColor;
        margin: 15px;
        @include decktop {
            padding: 10px;
        }
        @include tablet {
            display: flex;
            justify-content: center;
            height: 100%;
            gap: 5px;
        }
        @include mobile {
            padding: 5px;
            gap: 5px;
            margin: 5px;
        }
        .wrapper {
            padding-top: 50px;
            @include tablet {
                flex: 7 1;
                padding-top: 0px;
            }
            .posts {
                display: flex;
                flex-direction: column;
                gap: 150px;
                padding: 15px;
                @include tablet() {
                    gap: 0px;
                }
                @include mobile {
                    padding: 1px;
                }

                .post {
                    display: flex;
                    gap: 80px;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    margin-bottom: 30px;

                    @include tablet {
                        flex-direction: column;
                        align-items: center;
                    }

                    &::after {
                        content: '';
                        width: 98%;
                        border-bottom: 0.5px solid $firstColor;
                    }

                    &:nth-child(2n + 1) {
                        flex-direction: row-reverse;
                        @include tablet {
                            flex-direction: column;
                        }
                    }
                    .img {
                        flex: 0 0 40%;
                        position: relative;

                        @include tablet {
                            left: 30px;
                        }

                        &::after {
                            content: '';
                            width: 100%;
                            height: 100%;
                            background-color: $firstColor;
                            position: absolute;
                            bottom: -20px;
                            left: -20px;
                            z-index: -1;
                            @include tablet {
                                width: 80%;
                            }
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            @include tablet {
                                width: 80%;
                            }
                        }
                    }
                    .content {
                        flex: 0 1 40%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: stretch;
                        gap: 20px;

                        .contentDesc {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -moz-box;
                            -moz-box-orient: vertical;
                            display: -webkit-box;
                            -webkit-line-clamp: 8;
                            -webkit-box-orient: vertical;
                            line-clamp: 3;
                            box-orient: vertical;
                        }

                        p {
                            font-size: 16px;
                            line-height: 25px;
                            text-align: justify;
                            @include tablet {
                                font-size: 14px;
                            }
                            @include mobile {
                                font-size: 8px;
                                line-height: 1.25;
                            }
                        }

                        h2 {
                            font-size: 25px;
                            @include tablet {
                                font-size: 20px;
                            }
                            @include mobile {
                                font-size: 12px;
                                line-height: 1.25;
                            }
                        }

                        button {
                            border: none;
                            color: teal;
                            width: max-content;
                            padding: 10px 20px;
                            border: 1px solid teal;
                            background-color: #fff;
                            cursor: pointer;
                            @include mobile {
                                padding: 5px 10px;
                            }

                            &:hover {
                                border: 1px solid white;
                                color: black;
                                background-color: $firstColor;
                            }
                        }
                    }
                }
            }
            //SINGLE
            .single {
                display: flex;
                gap: 50px;
                padding: 15px;
                @include mobile {
                    padding: 1px;
                }

                .content {
                    flex: 5;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    padding: 15px;
                    @include tablet {
                        padding: 0;
                    }

                    h1 {
                        font-size: 42px;
                        color: #333;

                        @include tablet {
                            font-size: 25px;
                        }
                        @include mobile {
                            font-size: 12px;
                            line-height: 1.25;
                        }
                    }
                    p {
                        text-align: justify;
                        line-height: 25px;

                        @include mobile {
                            font-size: 8px;
                            line-height: 1.25;
                        }
                    }
                    img {
                        width: 100%;
                        height: 300px;
                        object-fit: contain;
                    }

                    .user {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        font-size: 14px;

                        @include mobile {
                            flex-direction: column;
                        }

                        img {
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            object-fit: cover;
                        }

                        .info {
                            span {
                                font-weight: bold;
                            }
                        }
                        .edit {
                            display: flex;
                            gap: 5px;

                            img {
                                width: 20px;
                                height: 20px;
                                cursor: pointer;
                                @include mobile {
                                    width: 30px;
                                    height: 30px;
                                }
                            }
                        }
                    }
                }
                .menu {
                    @include tablet {
                        display: none;
                    }
                    flex: 2;
                    display: flex;
                    flex-direction: column;
                    gap: 35px;

                    h2 {
                        font-size: 20px;
                        color: #555;
                        font-weight: bold;
                    }
                    .post {
                        display: flex;
                        flex-direction: column;
                        gap: 15px;

                        img {
                            width: 100%;
                            height: 200px;
                            object-fit: cover;
                        }
                        h2 {
                            color: #333;
                            font-weight: 600;
                        }
                        button {
                            width: max-content;
                            padding: 7.5px 15px;
                            border: none;
                            cursor: pointer;
                            color: teal;
                            background-color: #fff;
                            border: 1px solid teal;

                            &:hover {
                                border: 1px solid white;
                                background-color: $firstColor;
                                color: black;
                            }
                        }
                    }
                }
            }
            //WRITE
            .add {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                padding: 15px;
                @include mobile {
                    padding: 1px;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                .content {
                    flex: 5;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    @include mobile() {
                        font-size: 10px;
                    }

                    input {
                        padding: 10px;
                        border: 1px solid lightgray;
                        @include mobile() {
                            padding: 2px;
                            font-size: 10px;
                        }
                    }
                    .editorContainer {
                        height: 300px;
                        overflow: auto;
                        border: 1px solid lightgray;
                       

                        .editor {
                            height: 100%;
                            border: none;

                            .ql-container {
                                @include mobile() {
                                    font-size: 10px;
                                }
                            }
                            
                        }
                    }
                }
                .menu {
                    flex: 2;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .item {
                        border: 1px solid lightgray;
                        padding: 10px;
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        justify-content: space-between;
                        font-size: 12px;
                        color: #555;
                        max-height: 250px;
                        @include mobile {
                            padding: 5px;
                            font-size: 10px;
                        }
                        input {
                            @include mobile() {
                                
                                font-size: 10px;
                            }
                        }
                        h1 {
                            font-weight: 700;
                        }
                        h2 {
                            font-weight: 700;
                            font-size: 20px;
                        }
                        .file {
                            text-decoration: underline;
                            cursor: pointer;
                            font-size: 16px;
                            color: $fivethColor;
                            @include mobile {
                                font-size: 10px;
                            }
                        }
                        .buttons {
                            display: flex;
                            justify-content: space-between;
                            gap: 20px;

                            :first-child {
                                border: 1px solid teal;
                                cursor: pointer;
                                color: teal;
                                background-color: #fff;
                                padding: 3px 5px;
                            }
                            :last-child {
                                border: 1px solid teal;
                                cursor: pointer;
                                color: white;
                                background-color: teal;
                                padding: 4px 6px;
                            }
                        }
                        .cat {
                            display: flex;
                            align-items: center;
                            gap: 2px;
                            color: teal;
                        }
                    }
                }
            }
        }

        .link {
            color: inherit;
        }
        //LOGIN & REGISTER
        .auth {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $fivethColor;
            min-height: calc(100vh - 90px);

            .avatar {
                flex-shrink: 0;
                width: 90px;
                height: 90px;
                object-fit: cover;
                border-radius: 50%;
                overflow: hidden;
                margin-bottom: 20px;
            }
            @include tablet {
                min-width: calc(100vw - 90px);
            }
            @include mobile {
                min-width: calc(100vw - 10px);
            }

            h1 {
                font-size: 20px;
                color: $forthColor;
                margin-bottom: 20px;
                margin-top: 20px;
            }
            .successLinkButton {
                border: none;
                cursor: pointer;
                background-color: $firstColor;
                color: $forthColor;
                font-weight: 500;
                padding: 10px;
                min-width: 150px;
            }
            .successLink {
                font-size: 22px;
                color: $forthColor;
                text-align: center;
                margin-top: 5px;
                margin-bottom: 5px;

                @include mobile {
                    font-size: 20px;
                }
            }

            form {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                padding: 50px;
                background-color: $forthColor;
                margin-bottom: 20px;

                @include tablet {
                    padding: 40px;
                }

                @include mobile {
                    padding: 30px;
                }

                .formInput {
                    display: flex;
                    flex-direction: column;
                    // width: 280px;
                    input {
                        padding: 10px;
                        border: none;
                        border-bottom: 1px solid $fivethColor;
                        width: 250px;
                        @include mobile {
                            width: 200px;
                            padding: 15px;
                        }
                    }
                    label {
                        font-size: 12px;
                        color: gray;
                    }

                    .insturctions {
                        max-width: 250px;
                        margin-top: 5px;
                    }
                    .offscreen,
                    .hide {
                        display: none;
                    }
                    .icons {
                        margin-right: 5px;
                    }
                    .iconGreen {
                        color: green;
                    }
                    .iconRed {
                        color: red;
                    }
                }

                input {
                    padding: 15px;
                    border: none;
                    border-bottom: 1px solid $fivethColor;
                    width: 250px;
                    @include mobile {
                        width: 200px;
                    }
                }
                button {
                    border: none;
                    cursor: pointer;
                    background-color: $firstColor;
                    color: $forthColor;
                    font-weight: 500;
                    padding: 10px;
                    min-width: 200px;

                    &:disabled {
                        background-color: dimgrey;
                        color: linen;
                        opacity: 1;
                    }
                }
                .errmsg {
                    font-size: 16px;
                    color: red;
                    text-align: center;
                    margin-bottom: 10px;
                    max-width: 250px;
                    @include mobile {
                        font-size: 16px;
                    }
                }

                span {
                    font-size: 14px;
                    text-align: center;
                    @include mobile {
                        font-size: 10px;
                    }
                }

                p {
                    font-size: 16px;
                    color: $thirdColor;
                    text-align: center;
                    margin-top: 5px;
                    margin-bottom: 5px;

                    @include mobile {
                        font-size: 12px;
                    }
                }
            }
        }
        //NAVBAR
        .navbar {
            min-width: 135px;

            .navContainer {
                padding: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                @include decktop {
                    flex-wrap: wrap;
                    padding: 5px;
                    gap: 5px;
                }
                @include tablet {
                    flex-direction: column;
                    gap: 30px;
                }
                @include mobile {
                    padding: 1px;
                }
                .logo {
                    text-align: justify;
                    @include tablet {
                        text-align: center;
                    }
                }
                .logo > a {
                    font-size: 20px;
                    padding: 5px;
                    font-weight: bold;
                    font-style: italic;
                    color: $secondColor;
                    text-align: center;

                    @include tablet {
                        font-size: 16px;
                    }
                }
                .links {
                    // flex-shrink: 0;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    justify-content: space-around;
                    img {
                        flex-shrink: 0;
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-left: 20px;
                        @include decktop {
                            margin-left: 0;
                        }
                        @include tablet {
                            margin-top: 20px;
                        }
                    }
                    @include tablet {
                        flex-direction: column;
                        gap: 20px;
                        text-align: center;
                    }

                    h6 {
                        font-size: 17px;
                        font-weight: 400;
                        font-style: italic;
                        color: $secondColor;

                        @include tablet {
                            font-size: 14px;
                        }
                        @include mobile {
                            font-size: 12px;
                        }
                    }
                    span {
                        cursor: pointer;
                        font-size: 17px;
                        padding: 10px;
                        font-weight: bold;
                        font-style: italic;
                        color: $secondColor;
                        @include decktop {
                            padding: 0;
                        }
                        @include mobile {
                            font-size: 14px;
                        }
                    }
                    .write {
                        background-color: $firstColor;
                        width: 65px;
                        height: 65px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid #fff;
                        color: $forthColor;
                        font-size: 15px;
                        font-weight: 300;
                        font-style: normal;

                        &:hover {
                            color: teal;
                            background-color: #fff;
                            border: 1px solid teal;
                        }
                    }
                }
            }
            @include tablet {
                position: sticky;
                top: 50px;
                height: calc(100vh - 50px);
                overflow: scroll;
                flex: 4 0;
                border-right: 0.5px solid $firstColor;
            }
        }
        //FOOTER
        footer {
            margin-top: 100px;
            padding: 20px;
            background-color: $firstColor;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include tablet {
                display: none;
            }
            p {
                color: $thirdColor;
            }
            span {
                font-size: 12px;
            }
        }
    }
}
